<template>
  <div>  
    <v-bottom-navigation color="info" horizontal :background-color="color" dark>    
      <v-btn to="/admin/sys_config_collegemanpower">
        <span>ตั้งค่าระบบ </span>
        <v-icon>mdi-wrench</v-icon>
      </v-btn>

      <v-btn to="/admin/personnel_etc">
        <span>อัตรากำลังสถานศึกษา </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>

      <v-btn to="/admin/power_rate/rate_work_college">
        <span>จำนวนนักเรียน </span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>

      <v-btn to="/admin/rate_work_g">
        <span>รายงานอัตรากำลังประจำปี สถานศึกษา</span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูล ข้าราชการ ลูกจ้างประจำ พนักงานราชการ ครูอัตราจ้างหรือลูกจ้างชั่วคราว"
        class="px-5 py-3"
      >  
        <v-card class="pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="8" class="pa-1">
                <v-simple-table class="elevation-1">
                <thead style="background-color: wheat;">
                  <tr>
                    <th class="text-left font-weight-bold">
                      ประเภท
                    </th>
                    <th class="text-left font-weight-bold">
                      จำนวน (คน)
                    </th>
                    <th class="text-left font-weight-bold">
                      ประเภท
                    </th>
                    <th class="text-left font-weight-bold">
                      จำนวน (คน)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-left">ผู้อำนวยการ</td>
                    <td class="text-left">
                     <v-btn rounded color="info" :href="'#/admin/print_personnel_etc_type/' + 'director'" target="_blank"> <h3> {{ personnel_etcsCounts.directors }}</h3></v-btn> 
                    </td>
                    <td class="text-left">รองผู้อำนวยการ</td>
                    <td class="text-left">
                      <v-btn rounded color="info" :href="'#/admin/print_personnel_etc_type/' + 'se_director'" target="_blank"> <h3> {{ personnel_etcsCounts.se_directors }}</h3></v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">ข้าราชการครู</td>
                    <td class="text-left">
                      <v-btn rounded color="info" :href="'#/admin/print_personnel_etc_type/' + 'teacher'" target="_blank"> <h3>  {{ personnel_etcsCounts.teachers }}</h3></v-btn>
                    </td>
                    <td class="text-left"></td>
                    <td class="text-left">
                     
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">สนับสนุนการสอน</td>
                    <td class="text-left">
                      <v-btn rounded color="info" :href="'#/admin/print_personnel_etc_type/' + '38'" target="_blank"> <h3>  {{ personnel_etcsCounts.support_38 }}</h3></v-btn>
                    </td>
                    <td class="text-left">ลูกจ้างประจำ</td>
                    <td class="text-left">
                      <v-btn rounded color="info" :href="'#/admin/print_personnel_etc_type/' + 'permanent'" target="_blank"> <h3>  {{ personnel_etcsCounts.permanents }}</h3></v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">พนักงานราชการ(สายการสอน)</td>
                    <td class="text-left">
                      <v-btn rounded color="info" :href="'#/admin/print_personnel_etc_type/' + 'governmentteach'" target="_blank"> <h3> {{ personnel_etcsCounts.governmentteach }}</h3></v-btn>
                    </td>
                    <td class="text-left">ครูอัตราจ้าง</td>
                    <td class="text-left">
                      <v-btn rounded color="info" :href="'#/admin/print_personnel_etc_type/' + 'temporary'" target="_blank"> <h3> {{ personnel_etcsCounts.temporarys }}</h3></v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">พนักงานราชการ(สายสนับสนุน)</td>
                    <td class="text-left">
                      <v-btn rounded color="info" :href="'#/admin/print_personnel_etc_type/' + 'governmentsp'" target="_blank"> <h3> {{ personnel_etcsCounts.governmentsp }}</h3></v-btn>
                    </td>
                    <td class="text-left">ลูกจ้างชั่วคราว</td>
                    <td class="text-left">
                      <v-btn rounded color="info" :href="'#/admin/print_personnel_etc_type/' + 'temporary_job'" target="_blank"> <h3>{{ personnel_etcsCounts.temporary_jobs }}</h3></v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2"
                      class="text-center font-weight-bold"
                      style="background-color: wheat;"
                    >
                      รวมทั้งสิ้น
                    </td>
                    <td colspan="2"
                      class="text-center font-weight-bold"
                      style="background-color: wheat;"
                    >
                      {{ personnel_etcsCounts.sumAll }} คน
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="4">
             

              <div>
                <v-btn   
                 block
                  right
                  depressed
                  color="green"
                  @click="updateData_etc()"
                  >
                  ปรับปรุงข้อมูลบุคคล อัตรากำลังสถานศึกษา
                </v-btn>
                <v-btn
                  block
                  right
                  depressed
                  color="info"
                  :href="
                    '#/college/print_report_personnel_etc/' + user.college_code
                  "
                  target="_blank"
                >
                  <v-icon>mdi-printer</v-icon>พิมพ์รายชื่อ
                </v-btn>

                <v-btn block color="red" @click="delFormatFail()">
                  <v-icon>mdi-delete-sweep</v-icon> จัดการลบข้อมูลที่ไม่ถูกต้อง
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card>
          <span class="pa-2 font-weight-bold primary--text"
            >ค้นหาบุคคลจากฐานข้อมูลงานอัตรากำลังของสถานศึกษาอื่น ๆ ในระบบ</span
          >
          <v-row no-gutters>
            <v-col cols="12" md="8" class="pa-1">
              <v-text-field
                v-model="searchIDcard"
                append-icon="mdi-magnify"
                label="ค้นหาบุคลากรในระบบอัตรากำลังจากสถานศึกษาอื่น ๆ : ด้วยรหัสบัตรประชาชน"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-btn
                block
                right
                depressed
                color="primary"
                @click.native="personnel_etcSearchSYS()"
              >
                <v-icon>mdi-account-search</v-icon> ค้นหา
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="12"
              v-if="personnel_etcSYSs.personnel_etc_id_card"
              class="pa-2"
            >
              <v-form ref="addpersonnel_reqform" lazy-validation="">
                <v-alert outlined type="warning" prominent border="left">
                  <div>
                    {{ personnel_etcSYSs.college_name }} <br />
                    {{ personnel_etcSYSs.personnel_etc_id_card }}
                    {{ personnel_etcSYSs.personnel_etc_title }}
                    {{ personnel_etcSYSs.personnel_etc_fristname }}
                    {{ personnel_etcSYSs.personnel_etc_lastname }}
                  </div>                 
                </v-alert>
              </v-form>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" md="6" class="pa-2">
              <v-alert outlined color="green">
                <h3>รายการขอเพิ่มบุคคล</h3>

                <v-data-table
                  :headers="personnel_reqHearder"
                  :items="personnel_reqReqNTB"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:item.personel_reqIDcard="{ item }">
                    {{
                      item.personnel_etc_title +
                        item.personnel_etc_fristname +
                        " " +
                        item.personnel_etc_lastname
                    }}
                  </template>

                  <template v-slot:item.personel_reqStatus="{ item }">
                    <v-chip
                      dark
                      color="grey"
                      v-if="item.personel_reqStatus === 'req'"
                      >รอตรวจสอบ</v-chip
                    >
                  </template>

                  <template v-slot:item.personel_reqDate="{ item }">
                    {{
                      item.personel_reqDate
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </template>

                  <template v-slot:item.action="{ item }">
                    <v-btn
                      rounded
                      color="warning"
                      @click="
                        cancelReg(
                          item.personel_reqNewCollege,
                          item.personel_reqIDcard
                        )
                      "
                    >
                      <v-icon>mdi-close</v-icon> ยกเลิก</v-btn
                    >
                  </template>
                </v-data-table></v-alert
              >
            </v-col>
            <v-col cols="12" md="6" class="pa-2">
              <v-alert outlined color="warning">
                <h3>รายการขออนุมัติย้ายข้อมูลบุคคล</h3>

                <v-data-table
                  :headers="personnel_reqOHearder"
                  :items="personnel_reqReqOTB"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:item.personel_reqIDcard="{ item }">
                    {{
                      item.personnel_etc_title +
                        item.personnel_etc_fristname +
                        " " +
                        item.personnel_etc_lastname
                    }}
                  </template>

                  <template v-slot:item.personel_reqStatus="{ item }">
                    <v-btn
                      @click="personnel_reqApprove(item.personel_reqIDcard)"
                      color="green"
                      dark
                      v-if="item.personel_reqStatus === 'req'"
                      >อนุนัติย้ายข้อมูล</v-btn
                    >
                  </template>

                  <template v-slot:item.personel_reqDate="{ item }">
                    {{
                      item.personel_reqDate
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </template>
                </v-data-table>
              </v-alert>
            </v-col>
          </v-row>
        </v-card>
        <v-alert outlined color="red">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
            single-line
            hide-details
            clearable
            solo-inverted
            flat
            background-color="#546E7A"
            class="mb-2"
          />
          <v-select
            v-model="selectedHeaders"
            :items="headers"
            label="เลือกคอลัมน์ที่ต้องการแสดง"
            multiple
            outlined
            return-object
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 6">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 6" class="grey--text caption"
                >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
              >
            </template>
          </v-select>
         <v-autocomplete outlined  v-model="collegeSearch" label="ค้นหา วิทยาลัย" :items="colleges" item-text="college_name" item-value="college_code" @change="personnel_etcSearch()"></v-autocomplete>
        </v-alert>
        <!--    group-by="personnel_etc_m_type" -->
        <v-data-table
          :loading="loading"
          :headers="showHeaders"
          :items="personnel_etcs"
          :search="search"
          :item-class="row_classes"        
          disable-pagination
        >
          <template v-slot:item.personnel_etc_m_type="{ item }">
            <span
              class="warning--text font-weight-bold"
              v-if="item.personnel_etc_m_type === 'permanent'"
              >ลูกจ้างประจำ</span
            >
            <span
              class="warning--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'governmentteach'"
              >พนักงานราชการ(สายการสอน)</span
            >
            <span
              class="warning--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'governmentsp'"
              >พนักงานราชการ(สายสนับสนุน)</span
            >
            <span
              class="purple--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'temporary'"
              >ครูอัตราจ้าง</span
            >
            <span
              class="purple--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'temporary_job'"
              >ลูกจ้างชั่วคราว</span
            >
            <span
              class="primary--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'teacher'"
              >ข้าราชการครู</span
            >
            <span
              class="info--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'se_director'"
              >รองผู้อำนวยการ</span
            >
            <span
              class="info--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === 'director'"
              >ผู้อำนวยการ</span
            >
            <span
              class="primary--text font-weight-bold"
              v-else-if="item.personnel_etc_m_type === '38'"
              >38 ค. (2)</span
            >
            <span class="red--text font-weight-bold" v-else
              >ยังไม่ได้กำหนดปะเภท</span
            >
          </template>

          <template v-slot:item.personnel_etc_salary="{ item }">
            {{ Number(item.personnel_etc_salary).toLocaleString() }}
          </template>

          <template v-slot:item.personnel_etc_budget="{ item }">
            <span v-if="item.personnel_etc_budget === 'budget'"
              >จากงบประมาณ</span
            >
            <span v-else-if="item.personnel_etc_budget === 'income'"
              >จากรายได้สถานศึกษา</span
            >
          </template>

          <template v-slot:item.personnel_etc_subject="{ item }">
            <div
              v-if="
                item.personnel_etc_m_type === 'governmentteach' ||
                  item.personnel_etc_m_type === 'temporary' ||
                  item.personnel_etc_m_type === 'teacher'
              "
              class="text-center"
            >
              <v-chip v-if="item.personnel_etc_subject" color="primary">
                <span v-if="item.personnel_etc_subject === '1'"
                  >อุตสาหกรรม</span
                >
                <span v-else-if="item.personnel_etc_subject === '2'">
                  พาณิชยกรรม/บริหารธุรกิจ</span
                >
                <span v-else-if="item.personnel_etc_subject === '3'">
                  ศิลปกรรม</span
                >
                <span v-else-if="item.personnel_etc_subject === '4'">
                  คหกรรม</span
                >
                <span v-else-if="item.personnel_etc_subject === '5'">
                  เกษตรกรรม</span
                >
                <span v-else-if="item.personnel_etc_subject === '6'">
                  ประมง</span
                >
                <span v-else-if="item.personnel_etc_subject === '7'">
                  อุตสาหกรรมท่องเที่ยว</span
                >
                <span v-else-if="item.personnel_etc_subject === '8'">
                  อุตสาหกรรมสิ่งทอ</span
                >
                <span v-else-if="item.personnel_etc_subject === '9'">
                  เทคโนโลยีสารสนเทศและการสื่อสาร</span
                >
                <span v-else-if="item.personnel_etc_subject === '10'">
                  อุตสาหกรรมบันเทิงและดนตรี</span
                >
                <span v-else-if="item.personnel_etc_subject === '11'">
                  พาณิชยนาวี</span
                >
                <span v-else-if="item.personnel_etc_subject === '12'">
                  เทคโนโลยีและนวัตกรรมเกษตรอุตสาหกรรม</span
                >
                <span v-else-if="item.personnel_etc_subject === '00'">
                  กลุ่มทักษะชีวิต</span
                >
                <span v-else-if="item.personnel_etc_subject === '66'">
                  ระยะสั้น</span
                >
              </v-chip>
              <v-chip v-else color="warning">
                กรุณาเลือกประเภทวิชา
              </v-chip>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn fab small dark color="warning">
              <v-icon
                @click="personnel_etcEdit(item.personnel_etcIDNO)"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              color="red"
              @click="personnel_etcDelete(item.personnel_etcIDNO)"
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>
  
   

      <!-- V-model deletepersonnel_etcdialog -->
      <v-layout>
        <v-dialog
          v-model="deletepersonnel_etcdialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูล"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text class="">
              <v-form ref="deletepersonnel_etcform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      ยืนยันการลบข้อมูล :
                      <h3>{{ editpersonnel_etc.personnel_etc_id_card }}</h3>
                    </v-flex>
                    <v-flex xs12 md6
                      >{{ editpersonnel_etc.personnel_etc_title
                      }}{{ editpersonnel_etc.personnel_etc_fristname }}
                      {{ editpersonnel_etc.personnel_etc_lastname }}</v-flex
                    >
                    <v-flex xs12 md6></v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletepersonnel_etcdialog = false">
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletepersonnel_etcubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editpersonnel_etcdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editpersonnel_etcdialog" persistent max-width="80%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลบุคลากร"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text>
              <v-form ref="editpersonnel_etcform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-row no-gutters>
                        <v-col cols="12" md="12">
                          <v-select
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'se_director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'director' ||
                                editpersonnel_etc.personnel_etc_m_type === '38'
                            "
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_m_type"
                            :items="type_personnelGov"
                            item-text="text"
                            item-value="value"
                            label="ประเภทบุคลากร"
                            :rules="[v => !!v || '']"
                          ></v-select>

                          <v-select
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'government' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary_job' ||
                                editpersonnel_etc.personnel_etc_m_type === null
                            "
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_m_type"
                            :items="type_personnel"
                            item-text="text"
                            item-value="value"
                            label="ประเภทบุคลากร"
                            :rules="[v => !!v || '']"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <hr />
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            @input="validateInput()"
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_id_card"
                            label="รหัสบัตรประชาชน"
                            :rules="[
                              v => !!v || 'This field is required',
                              v => (!!v && v.length == 13) || '13  ID Card'
                            ]"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_title"
                            label="คำนำหน้าชื่อ"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_fristname"
                            label="ชื่อ"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_lastname"
                            label="นามสกุล"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-autocomplete
                            :items="workgroups"
                            item-text="workGroupName"
                            item-value="workGroupID"
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_workgroup"
                            label="งาน"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary_job'
                            "
                          ></v-autocomplete>

                          <v-text-field
                            outlined
                            v-model="
                              editpersonnel_etc.personnel_etc_id_position
                            "
                            label="เลขที่ตำแหน่ง"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'se_director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'supervision' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp'
                            "
                          ></v-text-field>

                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_level"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'se_director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'supervision'
                            "
                            label="อันดับ/ระดับ"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_rang"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'permanent' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'se_director' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'supervision'
                            "
                            label="วิทยฐานะ"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_type"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'government'
                            "
                            label="ประเภท"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_about"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'government'
                            "
                            label="ด้าน"
                          ></v-text-field>
                          <v-select
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_budget"
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'temporary' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary_job'
                            "
                            label="งบประมาณ"
                            :items="budget_type"
                            item-text="text"
                            item-value="value"
                          ></v-select>
                          <v-text-field
                            outlined
                            v-model="editpersonnel_etc.personnel_etc_salary"
                            label="อัตราเงินเดือน"
                            type="number"
                          ></v-text-field>

                          <v-autocomplete
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary'
                            "
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_subject"
                            :items="subject_types"
                            item-text="subject_typeName"
                            item-value="subject_typeID"
                            label="ประเภทวิชา"
                            @change="subject_typesSearchEdit()"
                          >
                            <template v-slot:item="{ item }">
                              <div>
                                <div style="font-weight: bold;">
                                  {{ item.subject_typeID }} :
                                  {{ item.subject_typeName }}
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>

                          <v-autocomplete
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary'
                            "
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_branch"
                            :items="rate_work_course_stds"
                            :item-text="
                              editpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseSTBranch'
                                : 'branch_name_th'
                            "
                            :item-value="
                              editpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseSTBranchID'
                                : 'id_course_branch'
                            "
                            label="สาขาวิชา (เฉพาะผู้ที่ทำหน้าที่สอน)"
                            @change="rate_work_course_stdSubBrachEdit()"
                          >
                            <template v-slot:item="{ item }">
                              <div>
                                <div style="font-weight: bold;">
                                  <span
                                    v-if="
                                      editpersonnel_etc.personnel_etc_subject ===
                                        '66'
                                    "
                                  >
                                    {{ item.short_courseSTBranchID }} :
                                    {{ item.short_courseSTBranch }}</span
                                  >
                                  <span v-else>
                                    {{ item.year_course }} :
                                    {{ item.branch_name_th }}</span
                                  >
                                </div>
                                <div style="">
                                  <span
                                    v-if="
                                      editpersonnel_etc.personnel_etc_subject ===
                                        '66'
                                    "
                                    >{{ item.short_courseGroupName }}</span
                                  >
                                  <span v-else>
                                    ประเภทวิชา : {{ item.type_course }}</span
                                  >
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>
                          <v-autocomplete
                            v-if="
                              editpersonnel_etc.personnel_etc_m_type ===
                                'teacher' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentteach' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'governmentsp' ||
                                editpersonnel_etc.personnel_etc_m_type ===
                                  'temporary'
                            "
                            outlined
                            v-model="addpersonnel_etc.personnel_etc_branch_sub"
                            :items="rate_work_course_stdSubs"
                            :item-text="
                              editpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseName'
                                : 'branch_sub_name_th'
                            "
                            :item-value="
                              editpersonnel_etc.personnel_etc_subject === '66'
                                ? 'short_courseID'
                                : 'id_course_branch_sub'
                            "
                            label="สาขางาน (เฉพาะผู้ที่ทำหน้าที่สอน)"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            outlined
                            v-model="
                              editpersonnel_etc.personnel_etc_education_1
                            "
                            label="วุฒิการศึกษา(สาขาวิชา) ต่ำกว่า ป.ตรี"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="
                              editpersonnel_etc.personnel_etc_education_2
                            "
                            label="วุฒิการศึกษา(สาขาวิชา) ปริญญาตรี"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="
                              editpersonnel_etc.personnel_etc_education_3
                            "
                            label="วุฒิการศึกษา(สาขาวิชา) ปริญญาโท"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="
                              editpersonnel_etc.personnel_etc_education_4
                            "
                            label="วุฒิการศึกษา(สาขาวิชา) ปริญญาเอก"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editpersonnel_etcdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editpersonnel_etcSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไขข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model personnel_etcCSVdialog -->
      <v-layout>
        <v-dialog v-model="personnel_etcCSVdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลบุคลากร"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form ref="personnel_etcCSVdialogform" lazy-validation>
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-btn
                            outlined
                            block
                            color="info"
                            :to="
                              '/college/personnel_etc_import/' +
                                user.college_code
                            "
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกแบบฟอร์มบันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <h2>
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv กรุณา Save as Type: CSV UTF-8 (Comma delimited)"
                              placeholder="ไฟล์ CSV ข้อมูลบุคลากร Save as Type: CSV UTF-8 (Comma delimited)"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                              :rules="[v => !!v || '']"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </h2>

                          <div class="red--text ma-5 text-center">
                            กรุณาตรวจสอบฟิลล์ รหัสบัตรประชาชน ให้อยู่ในรูปแบบ
                            Custom Type : 0
                          </div>

                          <v-btn
                            class="pa-2"
                            block
                            color="green"
                            @click.stop="csvpersonnel_etcSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="personnel_etcCSVdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>


        <!-- Video Dialog -->
    <v-layout>
      <v-dialog
        v-model="VideoDialogYoutubeConditionbranch"
        persistent
        max-width="80%"
      >
        <v-card class="pa-0">      
                <iframe
                  width="100%"
                  height="600px"
                  src="https://www.youtube.com/embed/AGr0Gx0hxME"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>       
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              rounded
              @click.stop="VideoDialogYoutubeConditionbranch = false"
              ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>


    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ApiKey: "HRvec2021",
      loading: false,
      updateImageDialog: false,
      search: "",
      searchIDcard: "",
      addpersonnel_etcdialog: false,
      editpersonnel_etcdialog: false,
      deletepersonnel_etcdialog: false,
      searchPersonnel_etcdialog: false,
      showimagedialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      currentPK: null,
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "ประเภท", align: "center", value: "personnel_etc_m_type" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        },
        { text: "รหัส", align: "center", value: "personnel_etc_id_card" },
        { text: "คำนำหน้าชื่อ", align: "center", value: "personnel_etc_title" },
        { text: "ชื่อ", align: "center", value: "personnel_etc_fristname" },
        { text: "นามสกุล", align: "center", value: "personnel_etc_lastname" },

        {
          text: "เลขที่ตำแหน่ง",
          align: "center",
          value: "personnel_etc_id_position"
        },
        { text: "อันดับ/ระดับ", align: "center", value: "personnel_etc_level" },
        { text: "วิทยฐานะ", align: "center", value: "personnel_etc_rang" },
        { text: "ประเภท", align: "center", value: "personnel_etc_type" },
        { text: "ด้าน", align: "center", value: "personnel_etc_about" },
        {
          text: "เงินที่ใช้จ้าง",
          align: "center",
          value: "personnel_etc_budget"
        },
        {
          text: "เงินเดือน",
          align: "center",
          value: "personnel_etc_salary"
        },
        {
          text: "ต่ำกว่า ป.ตรี",
          align: "left",
          value: "personnel_etc_education_1"
        },
        {
          text: "ปริญญาตรี",
          align: "left",
          value: "personnel_etc_education_2"
        },
        {
          text: "ปริญญาโท",
          align: "left",
          value: "personnel_etc_education_3"
        },
        {
          text: "ปริญญาเอก",
          align: "left",
          value: "personnel_etc_education_4"
        },
        {
          text: "ประเภทวิชา",
          align: "left",
          value: "personnel_etc_subject"
        },
        {
          text: "สาขาวิชา",
          align: "left",
          value: "personnel_etc_branch_n"
        },

        {
          text: "สาขาวิชางาน",
          align: "left",
          value: "personnel_etc_branch_Subn"
        },
        
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      pagination: {},
      personnel_etc: [],
      addpersonnel_etc: {},
      editpersonnel_etc: [],
      personnel_etcs: [],
      personnel_etc_sub: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      colleges: {},
      provinces: [],
      prefectures: [],
      regions: [],
      region_ena: true,
      rate_work_course_stds: [],
      rate_work_course_stdSubs: [],

      type_personnelGov: [
        { text: "ครู", value: "teacher" },
        { text: "รองผู้อำนวยการ", value: "se_director" },
        { text: "ผู้อำนวยการ", value: "director" },
        { text: "38 ค.(2)", value: "38" }
      ],

      type_personnel: [
        { text: "ลูกจ้างประจำ", value: "permanent" },
        { text: "พนักงานราชการ(สายการสอน)", value: "governmentteach" },
        { text: "พนักงานราชการ(สายสนับสนุน)", value: "governmentsp" },
        { text: "ครูอัตราจ้าง", value: "temporary" },
        { text: "ลูกจ้างชั่วคราว", value: "temporary_job" }
      ],
      budget_type: [
        { text: "จากงบประมาณ", value: "budget" },
        { text: "จากรายได้สถานศึกษา", value: "income" }
      ],
      personnel_etcsCounts: [],
      personnel_temporary: [],
      personnel_temporarys: [],
      searchadd_temporary: {},
      user: [],
      workgroups: [],
      subject_types: [],
      personnel_etcSYSs: [],
      addpersonnel_req: {},
      personnel_reqReqNTB: [],
      personnel_reqReqOTB: [],
      personnel_reqHearder: [
        { text: "ชื่อ-นามสกุล", align: "center", value: "personel_reqIDcard" },
        { text: "สังกัดเดิม", align: "center", value: "oldCollege" },
        { text: "วันที่ข้อมูล", align: "center", value: "personel_reqDate" },
        { text: "สถานะ", align: "center", value: "personel_reqStatus" },
        { text: "ยกเลิก", align: "center", value: "action" }
      ],
      personnel_reqOHearder: [
        { text: "ชื่อ-นามสกุล", align: "center", value: "personel_reqIDcard" },
        { text: "สังกัดใหม่", align: "center", value: "newCollege" },
        { text: "วันที่ข้อมูล", align: "center", value: "personel_reqDate" },
        { text: "สถานะ", align: "center", value: "personel_reqStatus" },
        { text: "ยกเลิก", align: "center", value: "action" }
      ],
      personnel_reqReqAp: [],
      personnel_reqReq_Pect: {},
      personnel_reqReq_DelPreq: {},
      validateInputcheck: [],
      personnel_etcCSVdialog: false,
      file2: null,
      VideoDialogYoutubeConditionbranch: false,
      colleges:[],
      collegeSearch:"",
      personnel_etc_updates:{},
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    this.workgroupQuery();
    this.collegeQuery();
    /* this.personnel_etcsQueryAll(); */
    this.rate_work_course_stdsQueryAll();
    this.personnel_etcsCount();
    this.subject_typeQuery(); 
    this.personnel_reqReqNTBQuery();
    this.personnel_reqReqOTBQuery();
  },

  methods: {
    async updateData_etc(){
      await this.sweetAlertLoading();
      this.personnel_etc_updates.ApiKey =this.ApiKey
      let result = await this.$http.post(
            "personnel_etc.update_admin.php",
            this.personnel_etc_updates
          );
      
          if (
            result.data.status == true
          ) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });         
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          Swal.close();
    },
    async delFormatFail(){
      await this.sweetAlertLoading();
      let clearFail 
      clearFail = await this.$http.post(
            "personnel_etc.clearFail.php",
            {ApiKey:this.ApiKey}
          );

           clearFail = await this.$http.post(
            "personnel_etc.clearFail2.php",
            {ApiKey:this.ApiKey}
          );

          clearFail = await this.$http.post(
            "personnel_etc.clearFail3.php",
            {ApiKey:this.ApiKey}
          );

          if (
            clearFail.data.status == true
          ) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
          }
          Swal.close();
    },

    async personnel_reqApprove(personel_reqIDcard) {
      let result = await this.$http.post("personnel_req.php", {
        ApiKey: this.ApiKey,
        personel_reqIDcard: personel_reqIDcard
      });
      this.personnel_reqReqAp = result.data;
      let personnelApp =
        this.personnel_reqReqAp.personnel_etc_title +
        this.personnel_reqReqAp.personnel_etc_fristname +
        " " +
        this.personnel_reqReqAp.personnel_etc_lastname;
      Swal.fire({
        title: "อนุมัติย้ายข้อมูลบุคคล",
        text: personnelApp,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          await this.sweetAlertLoading();
          this.personnel_reqReq_Pect.ApiKey = this.ApiKey;
          this.personnel_reqReq_Pect.personnel_etc_id_card = this.personnel_reqReqAp.personel_reqIDcard;
          this.personnel_reqReq_Pect.personnel_etc_college_code = this.personnel_reqReqAp.personel_reqNewCollege;

          this.personnel_reqReq_DelPreq.ApiKey = this.ApiKey;
          this.personnel_reqReq_DelPreq.personel_reqIDcard = this.personnel_reqReqAp.personel_reqIDcard;

          let resultUpdate_Petc = await this.$http.post(
            "personnel_etc.updateMove.php",
            this.personnel_reqReq_Pect
          );

          let resultDel_Preq = await this.$http.post(
            "personnel_req.delete.php",
            this.personnel_reqReq_DelPreq
          );

          if (
            resultDel_Preq.data.status == true &&
            resultUpdate_Petc.data.status == true
          ) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_etcsQueryAll();
            this.personnel_reqReqNTBQuery();
            this.personnel_reqReqOTBQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          Swal.close();
        }
      });
    },
    
    async personnel_etcSearch() {  
      let result = await this.$http
        .post("personnel_etc.php", {
          ApiKey: this.ApiKey,
          personnel_etc_college_code:this.collegeSearch          
        })
      this.personnel_etcs = result.data;

      let resultCoutn = await this.$http.post("personnel_etc.php", {
        ApiKey: this.ApiKey,
        count_personnel: "Ok", 
        personnel_etc_college_code:this.collegeSearch   
      });
      this.personnel_etcsCounts = resultCoutn.data;
 
              },
    
    async collegeQuery() {
      let result = await this.$http.post("college.php", {
        ApiKey: this.ApiKey,
      });
      this.colleges = result.data;
    },
    
    async VideoDialogConditionB() {
      this.VideoDialogYoutubeConditionbranch = true;
    },

    async cancelReg(personel_reqNewCollege, personel_reqIDcard) {
      Swal.fire({
        title: "ยกเลิกรายการร้องขอ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          await this.sweetAlertLoading();
          this.personnel_reqReq_DelPreq.ApiKey = this.ApiKey;
          this.personnel_reqReq_DelPreq.personel_reqNewCollege = personel_reqNewCollege;
          this.personnel_reqReq_DelPreq.personel_reqIDcard = personel_reqIDcard;

          let resultDel_Preq = await this.$http.post(
            "personnel_req.delete.college.php",
            this.personnel_reqReq_DelPreq
          );

          if (
            resultDel_Preq.data.status == true
          ) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_etcsQueryAll();
            this.personnel_reqReqNTBQuery();
            this.personnel_reqReqOTBQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          Swal.close();
        }
      });
    },

    async personnel_etcCSV() {
      this.personnel_etcCSVdialog = true;
    },

    async csvpersonnel_etcSubmit() {
      let result = "";
      let uploaded = null;
      if (this.file2) {
        let formData = new FormData();
        let filename = this.user.college_code + ".personnel_etc.csv";
        formData.append("file", this.file2);
        formData.append("filename", "../HRvecfiles/" + filename);
        formData.append("ApiKey", this.ApiKey);
        result = await this.$http.post("personnel_etcCSV.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        
        uploaded = true;
      } else {
        uploaded = false;
      }
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.personnel_etcsQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด กรุณาตรวจสอบรูปแบบไฟล์ การกำหนดบัตรประชา",
          showConfirmButton: false,
          timer: 1500
        });
        this.personnel_etcsQueryAll();
      }
      this.personnel_etcCSVdialog = false;
    },

  

    async personnel_reqReqNTBQuery() {
      let result = await this.$http.post("personnel_req.php", {
        ApiKey: this.ApiKey,
        personel_reqNewCollege: this.user.college_code
      });
      this.personnel_reqReqNTB = result.data;
    },

    async personnel_reqReqOTBQuery() {
      let result = await this.$http.post("personnel_req.php", {
        ApiKey: this.ApiKey,
        personel_reqOldCollege: this.user.college_code
      });
      this.personnel_reqReqOTB = result.data;
    },

  
    checkID() {
      let vauleID = this.addpersonnel_etc.personnel_etc_id_card;
      if (vauleID.length != 13) return false;
      for (i = 0, sum = 0; i < 12; i++)
        sum += parseFloat(vauleID.charAt(i)) * (13 - i);
      if ((11 - (sum % 11)) % 10 != parseFloat(vauleID.charAt(12)))
        return false;
      return true;
    },

    async validateInput() {
      const maxLength = 13;
      const regex = /^[0-9]\d*$/;
      let event = this.addpersonnel_etc.personnel_etc_id_card;
      let value = this.addpersonnel_etc.personnel_etc_id_card;
      let i, sum;
      const char =
        String.fromCharCode(event.keyCode) || String.fromCharCode(event.which);
      if (
        value !== undefined &&
        value.toString().length == maxLength &&
        value.match(regex)
      ) {
        if (value.length != 13) return false;
        for (i = 0, sum = 0; i < 12; i++) {
          sum += parseInt(value.charAt(i)) * (13 - i);
        }
        let mod = sum % 11;
        let check = (11 - mod) % 10;
        if (check == parseInt(value.charAt(12))) {
          this.validateInputcheck = "true";
        }
      } else {
        this.validateInputcheck = "false";
      }
    },

    async validateInput() {
      const maxLength = 13;
      const regex = /^[0-9]\d*$/;
      let event = this.addpersonnel_etc.personnel_etc_id_card;
      let value = this.addpersonnel_etc.personnel_etc_id_card;
      let i, sum;
      const char =
        String.fromCharCode(event.keyCode) || String.fromCharCode(event.which);
      if (
        value !== undefined &&
        value.toString().length == maxLength &&
        value.match(regex)
      ) {
        if (value.length != 13) return false;
        for (i = 0, sum = 0; i < 12; i++) {
          sum += parseInt(value.charAt(i)) * (13 - i);
        }
        let mod = sum % 11;
        let check = (11 - mod) % 10;
        if (check == parseInt(value.charAt(12))) {
          this.validateInputcheck = "true";
        }
      } else {
        this.validateInputcheck = "false";
      }
    },

    async subject_typesSearchAdd() {
      this.rate_work_course_stds = [];
      this.rate_work_course_stdSubs = [];
      let subject = this.addpersonnel_etc.personnel_etc_subject;
      if (subject != "66") {
        this.rate_work_course_stdsQueryAdd();
      } else {
        this.short_courseQueryAdd();
      }
    },

    async subject_typesSearchEdit() {
      this.rate_work_course_stds = [];
      this.rate_work_course_stdSubs = [];
      let subject =
        this.editpersonnel_etc.personnel_etc_subject ||
        this.addpersonnel_etc.personnel_etc_subject;
      if (subject != "66") {
        this.rate_work_course_stdsQueryAdd();
        this.rate_work_course_stdSubBrachEdit();
      } else {
        this.short_courseQueryAdd();
        this.rate_work_course_stdSubBrachEdit();
      }
    },
    async subject_typesSearchEditSearch() {
      this.rate_work_course_stds = [];
      this.rate_work_course_stdSubs = [];
      let subject = this.editpersonnel_etc.personnel_etc_subject;
      if (subject != "66") {
        this.rate_work_course_stdsQueryAddSearch();
        this.rate_work_course_stdSubBrachEdit();
      } else {
        this.short_courseQueryAdd();
        this.rate_work_course_stdSubBrachEdit();
      }
    },

    async subject_typeQuery() {
      let result = await this.$http.post("subject_type.php", {
        ApiKey: this.ApiKey
      });
      this.subject_types = result.data;
    },

    async workgroupQuery() {
      let result = await this.$http.post("workgroup.php", {
        ApiKey: this.ApiKey
      });
      this.workgroups = result.data;
    },

    async personnel_temporaryQuery() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.addpersonnel_etc.personnel_etc_id_card
      });
      this.personnel_temporarys = result.data;

      if (this.personnel_temporarys.id_card) {
        this.searchPersonnel_etcdialog = true;
      } else {
      }
    },

    async rate_work_course_stdsQueryAdd() {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey,
        id_type_course: this.addpersonnel_etc.personnel_etc_subject
      });
      this.rate_work_course_stds = result.data;
    },

    async rate_work_course_stdsQueryAddSearch() {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey,
        id_type_course: this.addpersonnel_etc.personnel_etc_subject
      });
      this.rate_work_course_stds = result.data;      
     
    },

    async short_courseQueryAdd() {
      let result = await this.$http.post("short_course.php", {
        ApiKey: this.ApiKey
      });
      this.rate_work_course_stds = result.data;
    },

    async rate_work_course_stdsQueryAll() {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey
      });
      this.rate_work_course_stds = result.data;
    },

    async rate_work_course_stdSubBrach() {
      let subject = this.addpersonnel_etc.personnel_etc_subject;
      if (subject != "66") {
        this.rate_work_course_stdSubs = [];
        let result = await this.$http.post("rate_work_course_std.php", {
          ApiKey: this.ApiKey,
          id_course_branch: this.addpersonnel_etc.personnel_etc_branch,
          searchGroupID: "Ok"
        });
        this.rate_work_course_stdSubs = result.data;
      } else {
        this.rate_work_course_stdSubs = [];
        let result = await this.$http.post("short_course.php", {
          ApiKey: this.ApiKey,
          short_courseSTBranchID: this.addpersonnel_etc.personnel_etc_branch
        });
        this.rate_work_course_stdSubs = result.data;
      }
    },

    async rate_work_course_stdSubBrachEdit() {
      let subject =
        this.addpersonnel_etc.personnel_etc_subject ||
        this.addpersonnel_etc.personnel_etc_subject;
      if (subject != "66") {
        this.rate_work_course_stdSubs = [];
        let result = await this.$http.post("rate_work_course_std.php", {
          ApiKey: this.ApiKey,
          id_course_branch:
            this.addpersonnel_etc.personnel_etc_branch ||
            this.personnel_temporarys.personnel_etc_branch,
          searchGroupID: "Ok"
        });
        this.rate_work_course_stdSubs = result.data;
      } else {
        this.rate_work_course_stdSubs = [];
        let result = await this.$http.post("short_course.php", {
          ApiKey: this.ApiKey,
          short_courseSTBranchID:
            this.addpersonnel_etc.personnel_etc_branch ||
            this.personnel_temporarys.personnel_etc_branch
        });
        this.rate_work_course_stdSubs = result.data;
      }
    },

    async personnel_etcsQueryAll() {
      this.loading = true;     
      let result = await this.$http
        .post("personnel_etc.php", {
          ApiKey: this.ApiKey
        })
      this.personnel_etcs = result.data;      
    },

    async personnel_etcSearchSYS() {
      let result = await this.$http.post("personnel_etc.php", {
        ApiKey: this.ApiKey,
        personnel_etc_id_card: this.searchIDcard
      });
      this.personnel_etcSYSs = result.data;
      if (result.data.personnel_etc_id_card) {
      } else {
        Swal.fire({
          icon: "warning",
          title: "ไม่พบข้อมูลบุคคลดังกล่าว",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async personnel_etcsCount() {     
      let result = await this.$http.post("personnel_etc.php", {
        ApiKey: this.ApiKey,
        count_personnel: "Ok",      
      });
      this.personnel_etcsCounts = result.data;
      let data = this.personnel_etcsCounts;
      let sum = 0;
      for (var el in data) {
        if (data.hasOwnProperty(el)) {
          sum += parseFloat(data[el]);
        }
      }
      this.personnel_etcsCounts.sumAll = sum;

    },

    numberWithCommas(x) {
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },
  

  

  

    //Edit data
    async personnel_etcEdit(personnel_etcIDNO) {     
      let result = await this.$http.post("personnel_etc.php", {
        ApiKey: this.ApiKey,
        personnel_etcIDNO: personnel_etcIDNO
      });
      this.editpersonnel_etc = result.data;   
       
      this.editpersonnel_etcdialog = true;
    },

    async editpersonnel_etcSubmit() {
      if (this.$refs.editpersonnel_etcform.validate()) {
        this.editpersonnel_etc.ApiKey = this.ApiKey;
        this.editpersonnel_etc.personnel_etc_subject = this.addpersonnel_etc.personnel_etc_subject;
        this.editpersonnel_etc.personnel_etc_branch = this.addpersonnel_etc.personnel_etc_branch;
        this.editpersonnel_etc.personnel_etc_branch_sub = this.addpersonnel_etc.personnel_etc_branch_sub;

        let result = await this.$http.post(
          "personnel_etc.update.php",
          this.editpersonnel_etc
        );
        if (result.data.status == true) {
          this.personnel_etc = result.data;
          Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_etcSearch();
          this.personnel_etcsCount();
        } else {
          Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editpersonnel_etcdialog = false;
      }
    },

    //DELETE data
    async personnel_etcDelete(personnel_etcIDNO) {
      let result = await this.$http.post("personnel_etc.php", {
        ApiKey: this.ApiKey,
        personnel_etcIDNO: personnel_etcIDNO
      });
      this.editpersonnel_etc = result.data;
      this.deletepersonnel_etcdialog = true;
    },

    async deletepersonnel_etcubmit() {
      if (this.$refs.deletepersonnel_etcform.validate())
        this.editpersonnel_etc.ApiKey = this.ApiKey;
      {
        let result = await this.$http.post(
          "personnel_etc.delete.php",
          this.editpersonnel_etc
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_etcSearch();        
        } else {
          Swal.fire({
            icon: "warning",
            title: "ลบข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletepersonnel_etcdialog = false;
      }
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },
    row_classes(item) {
      if (!item.personnel_etc_m_type || item.personnel_etc_m_type == "") {
        return "yellow";
      } else if (
        !item.personnel_etc_subject &&
        item.personnel_etc_m_type == "teacher"
      ) {
        return "amber";
      } else if (
        !item.personnel_etc_subject &&
        item.personnel_etc_m_type == "temporary"
      ) {
        return "amber";
      } else if (
        !item.personnel_etc_subject &&
        item.personnel_etc_m_type == "governmentteach"
      ) {
        return "amber";
      }
    }
  },

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
    color() {
      return "brown-darken-4";
    }
  }
};
</script>
<style>
.v-data-table thead th {
  font-size: 16px !important;
}
</style>
